import { GetApp } from '@mui/icons-material';
import { Alert, Box } from '@mui/material';
import { styled } from '@mui/system';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useBulkDownloadMutation } from 'api/redux/services/documentApi';
import { documentsState } from 'api/redux/DocumentsReducer';
import { IconBtn } from 'common/components/IconBtn';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

const IconWrapper = styled(Box)({
	position: 'absolute',
	right: 0,
});

export const BulkDownloadButton: FC = () => {
	const { selectedDocTypeIds } = useSelector(documentsState);
	const { grants } = useSelector(selectDropdown);
	const { currentSponsor, currentFund, currentInvestor } = grants;
	const [
		bulkDownloadRequest,
		{ isLoading, isError, error, isSuccess, data: zippedDocs },
	] = useBulkDownloadMutation();

	const handleBulkDownload = () => {
		bulkDownloadRequest({
			sponsorId: currentSponsor.id,
			fundId: currentFund.id,
			investorId: currentInvestor.id,
			documentTypeIds: selectedDocTypeIds,
		});
	};

	useEffect(() => {
		if (!isSuccess || !zippedDocs) return;

		const blobUrl = window.URL.createObjectURL(zippedDocs);

		const link = document.createElement('a');
		link.href = blobUrl;
		link.download = 'documents.zip';
		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
		window.URL.revokeObjectURL(blobUrl);
	}, [isSuccess, zippedDocs]);

	return (
		<>
			<IconWrapper>
				<IconBtn
					tooltip="Download filtered documents"
					onClick={handleBulkDownload}
					customColor={true}
					disabled={isLoading || isError}
				>
					{isLoading ? <LoadingSpinner /> : <GetApp />}
				</IconBtn>
			</IconWrapper>
			{isError && (
				<Alert severity="error">
					An error occurred on download documents{' '}
					{error ? `: ${JSON.stringify(error)}` : ''}
				</Alert>
			)}
			{isSuccess && (
				<Alert severity="success">
					Download request submitted successfully. Check your email for a
					download link.
				</Alert>
			)}
		</>
	);
};
